
import { Component, Vue, Prop } from "vue-property-decorator";
import NdaArticle from "./NdaArticle.vue";
import Proxy from "../Proxy";
import FormattedText from "./FormattedText.vue";
@Component({
  components: { NdaArticle, FormattedText }
})
export default class ProductCategory extends Vue {
  @Prop() id!: number;
  description = null;
  icon = null;
  name = null;
  products=[]
  mounted() {
    Proxy.get(`/api/1.0/productCategories/${this.id}`).then(res => {
      console.log(res.data);
      this.description = res.data.description;
      this.icon = res.data.icon;
      this.name = res.data.name;
      this.products = res.data.products;
    });
  }
}
