
import { QInput } from "quasar";
import { Component, Vue, Model, Watch } from "vue-property-decorator";
import PasswordInput from "./PasswordInput.vue";
import Proxy from "../Proxy";
import { getIsTenant } from "@/isTenant";
@Component({
  components: { PasswordInput },
})
export default class UserDataEdition extends Vue {
  private name = "Login";
  // -- form -- //
  private passwordOne: string | null = null;
  private passwordTwo: string | null = null;
  private email: string | null = null;
  private names: string | null = null;
  private surnames: string | null = null;
  private birthday: string | null = null;
  private cellphone: string | null = null;
  private neighborhood: string | null = null;
  isTenant: boolean = getIsTenant();
  @Watch("passwordOne")
  passwordOnechanges(newVal: string, oldVal: string) {
    const passwordTwoRef = this.$refs["passwordTwoRef"];
    if (passwordTwoRef instanceof QInput) {
      (passwordTwoRef as QInput).validate();
    }
  }
  mounted() {
    Proxy.get("/api/1.0/yo").then((res) => {
      this.cellphone = res.data.phone;
      this.names = res.data.firstName;
      this.surnames = res.data.lastName;
      this.email = res.data.email;
      this.neighborhood = res.data.neighborhood;
      this.birthday = res.data.birthday.replace(new RegExp("T.*"), "");
    });
  }

  async onSubmit() {
    const req: Record<string, string | null> = {
      firstName: this.names,
      lastName: this.surnames,
      birthday: this.birthday,
      email: this.email,
      phone: this.cellphone,
      neighborhood: this.neighborhood,
    };
    if (
      this.passwordOne == this.passwordTwo ||
      (this.passwordTwo && this.passwordTwo.length > 0)
    ) {
      req["newPassword"] = this.passwordOne;
    }
    await Proxy.put("/api/1.0/yo", req);
    this.$router.go(-1);
  }
}
