const isTenant = (
    (window.location.hostname.split('.').length > 3 || 
    (window.location.hostname.includes('localhost') && window.location.hostname.split('.').length > 1)) &&
    !window.location.hostname.split('.')[0].includes('app') &&
    !window.location.hostname.split('.')[0].includes('aws')
);

function getIsTenant() {
    return isTenant;
}

export { getIsTenant };