
import { Component, Vue } from "vue-property-decorator";
import Proxy from "../Proxy";
import PublicScreen from "./PublicScreen.vue";
import PasswordInput from "./PasswordInput.vue";
import { logEvent } from "../Firestore";
import { getIsTenant } from "../isTenant";

@Component({
  components: { PublicScreen, PasswordInput },
})
export default class Login extends Vue {
  private name = "Login";
  private isPwd = true;
  private isTentant: boolean = getIsTenant();

  async mounted() {
    await logEvent("login_screen_mounted")
    const res = await Proxy.get("/admin/login/", {
      data: null,
      headers: { "Content-Type": "application/json" },
    });
    if (res.data.response.user?.id) this.$router.push("/");
  }

  password = null;
  email = null;
  async onSubmit() {
    //dummy get to get the csrf token
    await Proxy.get("/admin/login/", {
      data: null,
      headers: { "Content-Type": "application/json" },
    });

    try {
      await Proxy.post("/admin/login/", {
        email: this.email,
        password: this.password,
        '["remember_me"]': true,
      });
      await logEvent("successful_login",{email:this.email})
      this.$router.push("/");
    } catch (error) {
      this.$q.notify("Error en las credenciales.");
      console.error(error.response);
      await logEvent("bad_login",{email:this.email})
    }
  }
}
