import { render, staticRenderFns } from "./Measurements.vue?vue&type=template&id=00b4e320&scoped=true"
import script from "./Measurements.vue?vue&type=script&lang=ts"
export * from "./Measurements.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00b4e320",
  null
  
)

export default component.exports
import {QScrollArea,QChip,QBtn,QDialog,QCard,QCardSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QScrollArea,QChip,QBtn,QDialog,QCard,QCardSection})
