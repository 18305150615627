
import { Component, Vue, Model, Prop } from "vue-property-decorator";
@Component
export default class PasswordInput extends Vue {
  @Model("input") value!: string;
  passwordChange(e: string) {
    this.$emit("input", e);
  }
  @Prop({ type: String, default: undefined }) hint?: string;
  @Prop({ type: String, default: undefined }) label?: string;
  @Prop({ type: Array, default: [] }) rules?: Array<object>;
  showPassword = false;
}
