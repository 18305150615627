
import { Component, Vue } from "vue-property-decorator";
import Proxy from "../Proxy";
import { openURL } from "quasar";

@Component
export default class Videos extends Vue {
  videos = [];
  mounted() {
    Proxy.get("/api/1.0/videos").then(res => {
      this.videos = res.data.videos;
      console.log(res.data.videos);
    });
  }
  open(link: string) {
    openURL(link);
  }
}
