
import { Vue, Component, Watch } from "vue-property-decorator";
import Proxy from "../../Proxy";
import { FoodCardProps } from "./AlimentaryRecord.vue";

@Component
export default class EditFood extends Vue {
  foodId = this.$route.params.id;
  public image: File | null = null;
  public foodDescription = "";
  public date = "";
  public typeOfFood = "";
  public imagePreview: string | null = null;
  public isLoading = false;

  @Watch("image")
  onImageChange() {
    if (this.image) {
      this.imagePreview = URL.createObjectURL(this.image);
    } else {
      this.imagePreview = null;
    }
  }

  mounted() {
    Proxy.get<FoodCardProps>(`/api/1.0/food/${this.$route.params.id}`)
      .then(response => {
        if (response.data.image) {
          this.imagePreview = `${response.data.image}`;
        }
        this.foodDescription = response.data.food_description;
        this.date = response.data.date;
        this.typeOfFood = response.data.type_of_food;
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.$router.push("/");
        }
        this.$q.notify({
          color: "negative",
          position: "top",
          message: "Lo sentimos, algo salió mal, por favor, intente más tarde"
        });
      });
  }
  // ------------------ events ------------- //
  public onRejected() {
    this.$q.notify({
      color: "negative",
      position: "top",
      message:
        "El archivo seleccionado no es una imagen válida o excede el tamaño permitido (10 MB)."
    });
  }

  public onFileChange(e: Event): void {
    const inputElement = e.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.image = file;
      };
      reader.readAsDataURL(file);
    }
  }
  // --------------- form submit ------------ //
  public async onSubmit() {
    if (!this.foodDescription || !this.date || !this.typeOfFood) {
      this.$q.notify({
        color: "negative",
        position: "top",
        message: "Por favor, rellene todos los campos requeridos"
      });
      return;
    }

    // Confirmation dialog:
    this.$q
      .dialog({
        title: "Guardar alimento",
        message: "¿Está seguro que desea guardar este alimento?",
        cancel: true,
        persistent: true
      })
      .onOk(() => {
        //on Ok we make the request:
        this.isLoading = true;
        const formData = new FormData();
        formData.append("food_description", this.foodDescription!);
        formData.append("date", this.date);
        formData.append("type_of_food", this.typeOfFood!);
        if (this.image) {
          formData.append("image", this.image);
        }
        formData.append("state", "Sin enviar");
        const config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };

        Proxy.put(`/api/1.0/food/${this.foodId}`, formData, config)
          .then(() => {
            // Everything is ok, we return to the last page:
            this.isLoading = false;
            this.$router.go(-1);
          })
          .catch(() => {
            // Something went wrong, we notify the user:
            this.isLoading = false;
            this.$q.notify({
              color: "negative",
              position: "top",
              message: "Error al guardar el alimento"
            });
          });
      });
  }
}
