
import { Component, Vue, Watch } from "vue-property-decorator";
import Proxy from "../Proxy";
import { openURL } from "quasar";
import { sortedPlanItems } from "./Logic";

interface Recipe {
  name: string
  image: string
  link: string
  id: number
  description:string
}

interface PlanItem {
  name: string;
  endTime: string;
  id: number;
}

interface Day{
  name:string,
  date:Date
}

type MenuResponse=Array<{
  description:string
  endTime:string
  icon:string
  id:number
  image:string
  name:string
  recipe:Recipe|null
}>

@Component
export default class RecipesGallery extends Vue {
  menu:MenuResponse|null=null
  selectedDay = 0;
  get days(): readonly Day[]{
    const ret:Day[]=[]
    for (let i=0;i<7;i++){
      const date=new Date()
      date.setDate(date.getDate()+i)
      ret.push({
        date,
        name:[
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado"
        ][date.getDay()]
      })
    }
    return ret;
  }
  @Watch("selectedDay")
  async changeSelectedDay(newVal: number | null) {
    this.fetchMenu()
  }
  async fetchMenu(): Promise<void> {
    const day:Day=this.days[this.selectedDay]
    const res = await Proxy.get<MenuResponse>("/api/1.0/menu", {
      params:{
        month:day.date.getMonth(),
        date:day.date.getDate(),
      }
    });
    this.menu=sortedPlanItems(res.data)
  }
  mounted() {
    this.fetchMenu()
  }
}
