
import { Component, Vue, Watch } from "vue-property-decorator";
import Proxy from "../Proxy";
import PublicScreen from "./PublicScreen.vue";
import { logEvent } from "../Firestore";
@Component({
  components: { PublicScreen }
})
export default class ForgotPassword extends Vue {
  email = null;
  mounted(){
    logEvent("forgot_password_screen_opened")
  }

  async onSubmit() {
    try {
      const res = await Proxy.post("/api/1.0/forgotPassword", {email: this.email});
      this.$q.dialog({
        message: `Enviamos un link de recuperación a <strong>${this.email}</strong>. Ya estás más cerca de tu meta. Revisa tu bandeja de entrada.`,
        title: "Éxito!",
        html: true,
        ok: "¡Genial!"
      });
      await logEvent("forgot_password_button_click_good_email",{email: this.email})
    } catch (err) {
      this.$q.notify("Dirección de correo electrónico incorrecta.");
      await logEvent("forgot_password_button_click_bad_email",{email: this.email})
    }
  }
}
