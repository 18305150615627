import Proxy from "./Proxy";
import { initializeApp, getApps, FirebaseApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';
import { getAnalytics, logEvent as firebaseLogEvent, Analytics } from 'firebase/analytics';

let firebaseApp: FirebaseApp | undefined = undefined;
let firestore: Firestore | undefined = undefined;
let messaging: Messaging | undefined = undefined;
let analytics: Analytics | undefined = undefined;

let databaseName: string | undefined = undefined;

async function initializeFirebase() {
    if (getApps().length === 0) {
        const configResponse = await Proxy.get('/api/1.0/web_app_config');
        const config = configResponse.data;
        databaseName = config.firestore_database;
        try {
            firebaseApp = initializeApp({
                ...config,
                projectId: 'nda-gcloud-1'
            });
        } catch (e) {
            console.log(e);
        }
    }
}

async function signInToFirebase() {
    const patientCustomTokenResponse = await Proxy.get('/api/1.0/patient_custom_token');
    const customToken = patientCustomTokenResponse.data?.custom_token;
    if (firebaseApp) {
        const auth = getAuth(firebaseApp);
        await signInWithCustomToken(auth, customToken);
    }
}

function getNewFirestore(): Firestore {
    if (firebaseApp) {
        if (databaseName !== undefined) {
            return getFirestore(databaseName);
        }
        return getFirestore();
    }
    throw new Error('Firebase app is not initialized.');
}

function getNewMessaging(): Messaging | undefined {
    try {
        if (firebaseApp) {
            return getMessaging(firebaseApp);
        }
    } catch (e) {
        console.error(e);
    }
}

function getNewAnalytics(): Analytics | undefined {
    if (firebaseApp) {
        return getAnalytics(firebaseApp);
    }
}

async function initializeFirebaseCompletely(): Promise<void> {
    if (firestore === undefined) {
        await initializeFirebase();
        await signInToFirebase();
        firestore = getNewFirestore();
        messaging = getNewMessaging();
    }
}

export async function logEvent(name: string, data?: object): Promise<void> {
    await initializeFirebase();
    if (analytics === undefined) {
        analytics = getNewAnalytics();
    }
    if (analytics === undefined) {
        return;
    } else {
        await firebaseLogEvent(analytics, name, data);
    }
}

export async function getFirestoreInstance(): Promise<Firestore> {
    await initializeFirebaseCompletely();
    if (firestore === undefined) {
        throw "firebase was not initialized successfully";
    }
    return firestore;
}

export async function requestNotificationPermission() {
    console.log("REQUESTING PERMISSION");
    await initializeFirebaseCompletely();
    console.log("completely initialized");
    if (messaging === undefined) {
        throw "firebase was not initialized successfully";
    }
    console.log("messaging is not undefined");
    const vapidKey = 'BD7j9P-BBK5eIlNVUZ0uXmtKe-lLk4K6-P1EoboSNyXr7TqIHBp0hDBlbYJYJ3xutLVXbUWpx8rOwDsvpKfuBoQ';
    try {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        console.log("Tengo la data");
        const messagingToken = await getToken(messaging, { vapidKey, serviceWorkerRegistration });
        console.log("Tengo el messaging token", messagingToken);
        await Proxy.post('/api/1.0/user_messaging_token', { messagingToken });
    } catch (e) {
        console.error(e);
    }
}
