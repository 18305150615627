
import { Component, Vue, Prop } from "vue-property-decorator";
import NdaArticle from "./NdaArticle.vue";
import Proxy from "../Proxy";

@Component({
  components: { NdaArticle }
})
export default class Recipe extends Vue {
  @Prop() id!: number;
  description = null;
  image = null;
  name = null;
  link = null;
  mounted() {
    Proxy.get(`/api/1.0/recipes/${this.id}`).then(res => {
      console.log(res.data);
      this.description = res.data.description;
      this.image = res.data.image;
      this.name = res.data.name;
      this.link = res.data.link;
    });
  }
}
