
import { Component, Vue, Watch } from "vue-property-decorator";
import Proxy from "../Proxy";
import { Firestore, doc, onSnapshot } from 'firebase/firestore';
import { getFirestoreInstance } from "../Firestore";
import FormattedText from "./FormattedText.vue";
import { getIsTenant } from "@/isTenant";

interface RoutingElement{
      route: string
      label: string
      visible: boolean
      location: string
      iconName: string
}

@Component({
  components:{FormattedText}
})
export default class Main extends Vue {
  left = false;
  canAccess:boolean|null=null;
  canSeeShoppingList=false;
  canSeeAlimentaryRecord = false;
  firestore: Firestore | null = null;
  preapprovalId=""
  unreadChatMessages=0
  isTenant: boolean = getIsTenant();


  routingElements : RoutingElement[] = [
    {
      route: '/plan/',
      label: 'Plan',
      visible: true,
      location: 'drawer',
      iconName: 'none'
    }, {
      route: '/measurements/',
      label: 'Mediciones',
      visible: false,
      location: 'drawer',
      iconName: "img:"+require('../assets/sidebar/measurements_icon.svg')
    }, {
      route: '/chat/',
      label: 'Chat',
      visible: false,
      location: 'drawer',
      iconName: "img:"+require('../assets/sidebar/chat_icon.svg')
    },{
      route: '/videos/',
      label: 'Videos',
      visible: false,
      location: 'drawer',
      iconName: "img:"+require('../assets/sidebar/videos_icon.svg')
    }, {
      route: '/recipes/',
      label: 'Recetas',
      visible: false,
      location: 'drawer',
      iconName: "img:"+require('../assets/sidebar/recipes_icon.svg')
    },{
      route: '/menu/',
      label: 'Menú semanal',
      visible: false,
      location: 'drawer',
      iconName: "img:"+require('../assets/sidebar/weekly_menu_icon.svg')
    }
  ]

  mounted() {
    Proxy.get("/api/1.0/yo").then((res) => {
      const canAccess=res.data["canAccess"]
      this.canAccess = canAccess
      this.routingElements[0].visible = canAccess
      this.routingElements[1].visible = res.data["canSeeAnthropometry"] && canAccess;
      this.routingElements[2].visible = res.data["canSeeChat"] && canAccess;
      this.routingElements[3].visible = res.data["canSeeVideos"] && canAccess && !this.isTenant;
      this.routingElements[4].visible = res.data["canSeeRecipes"] && canAccess;
      this.routingElements[5].visible = res.data["canSeeWeeklyMenu"] && canAccess && !this.isTenant;
      this.canSeeShoppingList = res.data["canSeeShoppingList"] && canAccess;
      this.canSeeAlimentaryRecord = res.data["canSeeAlimentaryRecord"] && canAccess;
      if (this.isTenant) {
        this.canSeeAlimentaryRecord = false
      }
      this.preapprovalId=res.data["preapprovalId"];
      let visibleElements = 0;
      this.routingElements.forEach((element, index) => {
        if(visibleElements < 3 && element.visible){
          element.location = 'tab'
          visibleElements++;
        }
      })
      return res.data["userId"];
    }).then(userId => {
      return getFirestoreInstance().then(firestore => {
        const unreadMessagesDoc = doc(firestore, `unreadMessages/${userId}`);
        return onSnapshot(unreadMessagesDoc, (snapshot) => {
          this.unreadChatMessages = snapshot.get('count') ?? 0;
        });
      });
    });
  }
  async onLogout() {
    try {
      await Proxy.get("/admin/logout/", {
        data: null,
        headers: { "Content-Type": "application/json" },
      });
    } catch (_) {
      console.log("Error on logout request! but it works!");
    }
    this.$router.push("/login");
  }
  get showTabs():boolean{
    return (!this.$route.matched.some(record=>record.meta.showTabs===false)) && (this.canAccess!==false)
  }
  get vueAppVersion(){
    return process.env.VUE_APP_VERSION
  }
  async downloadPlan(){
    const res = await Proxy.get("/api/1.0/plan/yo/plan.pdf", {responseType:"blob"})
    
    const fileURL = window.URL.createObjectURL(new Blob([res.data]));

    const link = document.createElement('a')
    link.href = fileURL
    link.download = res.headers['content-disposition'].match(/(?=filename=).*/g)[0].replace("filename=","")
    document.body.appendChild(link);
    link.click();
  }
}
