
import { Component, Vue, Prop } from "vue-property-decorator";
import FormattedText from "./FormattedText.vue";

@Component({
  components: {
    FormattedText,
  },
})
export default class NdaArticle extends Vue {
  @Prop({required:true}) icon!: string;
  @Prop({required:true}) name!: string;
  @Prop({required:false}) sectionName!: string;
  @Prop({required:true}) description!: string;
  @Prop({required:true}) bannerSrc!: string;
  @Prop({required:false}) image!: string;
  @Prop({required:false}) caption!: string|undefined;
}
