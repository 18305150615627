
import { Component, Vue } from "vue-property-decorator";
import Proxy from "../Proxy";
import FormattedTextLight from "./FormattedTextLight.vue";
import { openURL } from "quasar";
import { getIsTenant } from "@/isTenant";

interface HomeResponse {
  currentItem: {
    description: string;
    icon: string;
    image: string;
    startTime: string;
    endTime: string;
    name: string;
    id: number;
  };
  canSeeRecipes:boolean
  canSeeWeeklyMenu:boolean
  canSeeShoppingList:boolean
  canSeeVideos:boolean
  canSeeAlimentaryRecord:boolean
}

interface Link{
  imageSrc:any;
  targetUrl:string;
  label:string;
  enabled:boolean;
}

interface Advertisement{
  web_image:string
  app_image:string
  active:boolean
  priority:number
  link_web:string
  link_app:string
}

@Component({
  components: {
    FormattedTextLight,
  },
})
export default class Home extends Vue {
  slide=0
  autoplay=true
  advertisements:Advertisement[]=[]
  name: string | null = null;
  icon: string | null = null;
  image: string | null = null;
  description: string | null = null;
  currentItemId: number | null = null;
  startTime: string | null = null;
  endTime: string | null = null;
  canSeeRecipes = false;
  canSeeWeeklyMenu = false;
  canSeeShoppingList = false;
  canSeeVideos = false;
  canSeeAlimentaryRecord = false;
  isTenant: boolean = getIsTenant();
  get sortedAdvertisements(){
    return this.advertisements.sort((a,b)=>b.priority-a.priority)
  }

  get links():Link[]{
    return [
      {
        imageSrc:require('../assets/icon_recipes_button.ai.svg'),
        targetUrl:"recipes",
        label:"Recetas",
        enabled:this.canSeeRecipes
      },
      {
        imageSrc:require('../assets/icon_weekly_menu_button.svg'),
        targetUrl:"menu-semanal",
        label:"Menú Semanal",
        enabled:this.canSeeWeeklyMenu
      },
      {
        imageSrc:require('../assets/icon_shopping_list_button.svg'),
        targetUrl:"compras",
        label:"Lista de compras",
        enabled:this.canSeeShoppingList
      },
      {
        imageSrc:require('../assets/icon_videos_button.svg'),
        targetUrl:"videos",
        label:"Videos",
        enabled:this.canSeeVideos
      },
      {
        imageSrc:require('../assets/icon_alimentary_record.svg'),
        targetUrl:"registro_alimentario",
        label:"Registro alimentario",
        enabled:this.canSeeAlimentaryRecord
      }
    ].filter(link => link.enabled);
  }

  mounted() {
    const now = new Date();
    console.log("Now is:");
    console.log(now);
    Proxy.get<HomeResponse>("/api/1.0/homeInfo", {
      params: {
        hour: now.getHours(),
        minute: now.getMinutes(),
      },
    }).then((res) => {
      console.log(res.data);
      this.name = res.data.currentItem.name;
      this.icon = res.data.currentItem.icon;
      this.image = res.data.currentItem.image;
      this.description = res.data.currentItem.description;
      this.startTime = res.data.currentItem.startTime;
      this.endTime = res.data.currentItem.endTime;
      this.currentItemId = res.data.currentItem.id;
      this.canSeeRecipes = res.data.canSeeRecipes;
      this.canSeeWeeklyMenu = res.data.canSeeWeeklyMenu;
      this.canSeeShoppingList = res.data.canSeeShoppingList;
      this.canSeeVideos = res.data.canSeeVideos;
      this.canSeeAlimentaryRecord = res.data.canSeeAlimentaryRecord;
      if (this.isTenant) {
        this.canSeeAlimentaryRecord = false;
        this.canSeeVideos = false;
        this.canSeeWeeklyMenu = false;
      }
    });
    Proxy.get<Advertisement[]>("/api/1.0/advertisements").then((res)=>{
      this.advertisements=res.data
    })
  }

  open(link: string) {
    openURL(link);
  }
}
