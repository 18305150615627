
import { Component, Vue, Prop } from "vue-property-decorator";
import Proxy from "../Proxy";
import PublicScreen from "./PublicScreen.vue";
import PasswordInput from "./PasswordInput.vue";
import { logEvent } from "../Firestore";
@Component({
  components: { PublicScreen, PasswordInput }
})
export default class ResetPassword extends Vue {
  @Prop({ type: String, required: true }) token!: string;
  @Prop({ type: String, required: true }) email!: string;
  name = null;
  passwordOne = null;
  passwordTwo = null;
  async mounted() {
    await logEvent("password_reset_screen_shown",{email: this.email})
    Proxy.get(
      `/api/1.0/nameForPasswordReset?token=${this.token}&email=${this.email}`
    ).then(res => {
      this.name = res.data.name;
    });
  }

  async onSubmit() {
    const req: Record<string, string | null> = {
      token: this.token,
      email: this.email,
      password: this.passwordOne
    };
    await Proxy.post("/api/1.0/resetPassword", req);
    await logEvent("password_reset_successful_reset",{email: this.email})
    this.$router.push({ name: "login" });
  }
}
