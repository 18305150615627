import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=1605f47c"
import script from "./Chat.vue?vue&type=script&lang=ts"
export * from "./Chat.vue?vue&type=script&lang=ts"
import style0 from "./Chat.vue?vue&type=style&index=0&id=1605f47c&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QInfiniteScroll,QSpinner,QChatMessage,QFooter,QToolbar,QToolbarTitle,QInput,QBtn,QAvatar,QDialog,QCard,QCardSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QInfiniteScroll,QSpinner,QChatMessage,QFooter,QToolbar,QToolbarTitle,QInput,QBtn,QAvatar,QDialog,QCard,QCardSection})
