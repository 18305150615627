
import { Component, Vue, Prop } from "vue-property-decorator";
import Proxy from "../Proxy";
import NdaArticle from "./NdaArticle.vue";
import { getIsTenant } from "@/isTenant";

@Component({
  components: { NdaArticle }
})
export default class PlanItem extends Vue {
  @Prop() id!: number;
  description = null;
  image = null;
  icon = null;
  name = null;
  isTenant: boolean = getIsTenant();
  mounted() {
    Proxy.get(`/api/1.0/planItem/${this.id}`).then(res => {
      console.log(res.data);
      this.description = res.data.description;
      this.image = res.data.image;
      this.icon = res.data.icon;
      this.name = res.data.name;
    });
  }
}
