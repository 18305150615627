import { render, staticRenderFns } from "./WeeklyMenu.vue?vue&type=template&id=420547db&scoped=true"
import script from "./WeeklyMenu.vue?vue&type=script&lang=ts"
export * from "./WeeklyMenu.vue?vue&type=script&lang=ts"
import style0 from "./WeeklyMenu.vue?vue&type=style&index=0&id=420547db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "420547db",
  null
  
)

export default component.exports
import {QScrollArea,QChip,QCard,QItem,QItemSection,QAvatar,QImg,QItemLabel,QCardSection,QCardActions,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QScrollArea,QChip,QCard,QItem,QItemSection,QAvatar,QImg,QItemLabel,QCardSection,QCardActions,QBtn})
