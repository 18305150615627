
import Proxy from "../Proxy";
import { 
  getFirestore,
  Firestore,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  startAfter,
  limit,
  getDocs,
  addDoc,
  doc,
  setDoc } from 'firebase/firestore';
import { Component, Vue } from "vue-property-decorator";
import { getFirestoreInstance, requestNotificationPermission } from "../Firestore";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import imageCompression from 'browser-image-compression';
((TimeAgo as unknown) as any).addDefaultLocale(es);
const timeAgo = new TimeAgo("es");

type FsQuery<T> = any;
type FsDoc = any;

interface Message {
  patientUserId: string;
  sentDate: Date;
  text?:string;
  sentByPatient: boolean;
  type?:string;
  filename?: string;
}

function generalMessagesQuery(store: Firestore, patientUserId: string): FsQuery<FsDoc> {
  return query(
    collection(store, "messages"),
    where("patientUserId", "==", patientUserId.toString()),
    orderBy("sentDate", "desc")
  );
}

function toMessage(doc: any): Message {
  if ("patientUserId" in doc && "sentDate" in doc) {
    return {
      patientUserId: doc.patientUserId,
      text: doc.text,
      type: doc.type,
      filename: doc.filename,
      sentDate: doc.sentDate.toDate(),
      sentByPatient: doc.sentByPatient,
    };
  } else {
    throw new Error("Document is not a message");
  }
}

function listenForNewMessages(
  store: Firestore,
  patientUserId: string,
  callback: (newMessages: Message[]) => void,
  newestKnownMessageDate: Date
): () => void {
  return onSnapshot(
    query(
      generalMessagesQuery(store, patientUserId),
      where("sentDate", ">", newestKnownMessageDate)
    ),
    (snapshot) => {
      const newMessages = snapshot
        .docChanges()
        .filter((change) => change.type === "added")
        .map((change) => toMessage(change.doc.data()))
        .sort(
          (a, b) =>
            a.sentDate.getMilliseconds() - b.sentDate.getMilliseconds()
        );
      if (newMessages.length > 0) {
        callback(newMessages);
      }
    },
    (e: any) => {
      console.error(e);
    }
  );
}

async function loadMessages(
  store: Firestore,
  patientUserId: string,
  lastDate?: Date
): Promise<Message[]> {
  let messagesQuery = generalMessagesQuery(store, patientUserId);
  if (lastDate !== undefined) {
    messagesQuery = query(messagesQuery, startAfter(lastDate));
  }
  messagesQuery = query(messagesQuery, limit(5));
  const snapshot = await getDocs(messagesQuery);
  return snapshot.docs.map((docuSnapshot) => toMessage(docuSnapshot.data()));
}

async function getCompressedImage(file: File): Promise<File> {
  if (file.type.includes("image/")) {
    const compressed = await imageCompression(file, {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    });
    
    return new File([compressed], file.name, {
      lastModified: file.lastModified,
      type: file.type
    });
  } else {
    return file;
  }
}

@Component({
  filters: {
    convertago(value: Date) {
      return timeAgo.format(value);
    },
  },
})
export default class Chat extends Vue {
  firestore: Firestore | null = null;
  loadedMessages: readonly Message[] = [];
  userId: string | null = null;
  text = "";
  unsubscribe: (() => void) | null = null;
  name = "";
  nutritionistUserId = "";
  file: FileList | null = null;
  uploadProgress: number | null = null;
  mercadopagoStatus: boolean | null = null;
  locations: number[] | null = null;

  mounted() {
    getFirestoreInstance().then((firestore) => {
      this.firestore = firestore;
      this.listenForNewMessagesIfPossible();
    });
    Proxy.get("/api/1.0/yo").then((res) => {
      this.userId = res.data.userId.toString();
      this.nutritionistUserId = res.data.nutritionist_id.toString();
      this.name = `${res.data.lastName}, ${res.data.firstName}`;
      this.mercadopagoStatus = res.data.mercadopagoStatus;
      this.locations = res.data.locations;
      this.listenForNewMessagesIfPossible();
    });
  }

  listenForNewMessagesIfPossible() {
    if (this.firestore === null || this.userId === null) {
      return;
    } else {
      this.unsubscribe = listenForNewMessages(
        this.firestore,
        this.userId,
        (messages: Message[]) => {
          this.loadedMessages = Object.freeze([
            ...this.loadedMessages,
            ...messages.reverse(),
          ]);
        },
        new Date()
      );
      setDoc(doc(this.firestore, `unreadMessages/${this.userId}`), {
        count: 0,
        lastRead: new Date(),
      });
    }
  }

  unmounted() {
    if (this.unsubscribe !== null) {
      this.unsubscribe();
    }
  }

  async fileChosen() {
    const fileInput = this.$refs.fileInput as HTMLInputElement | undefined;

    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      this.uploadProgress = 0;
      const compressed = await getCompressedImage(fileInput.files[0]);
      const formData = new FormData();
      formData.append("file", compressed);
      const response = await Proxy.post("/api/1.0/chat_file_upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progress) => {
          this.uploadProgress = progress.loaded / progress.total;
        }
      });
      this.uploadProgress = null;
      await this.sendMessage({
        type: "file",
        filename: response.data["saved_filename"]
      });
    }
  }

  async loadMoreMessages(): Promise<void> {
    if (this.firestore === null || this.userId === null) {
      return;
    } else {
      const sentDate = this.loadedMessages.length === 0 ? undefined : this.loadedMessages[0].sentDate;
      return loadMessages(this.firestore, this.userId, sentDate).then(
        (messages) => {
          this.loadedMessages = Object.freeze([
            ...messages.reverse(),
            ...this.loadedMessages,
          ]);
        }
      );
    }
  }
  onLoad(index: bigint, done: (ended: boolean) => void) {
    const initialLength = this.loadedMessages.length;
    this.loadMoreMessages().then(() =>
      done(initialLength == this.loadedMessages.length)
    );
  }

  async sendMessage(message:object, beforeSend?:()=>void){
    if (this.firestore === null || this.userId === null) {
      return;
    } else {
      requestNotificationPermission()
        .then(() => {
          console.log("Notifications accepted!");
        })
        .catch((e) => {
          console.log("this error should only spawn in dev environment");
          console.error(e);
        });
      if(beforeSend){
        beforeSend()
      }
      await addDoc(collection(this.firestore, "messages"), {
        ...message,
        patientUserId: this.userId,
        sentDate: new Date(),
        sentByPatient: true,
        nutritionistUserId: this.nutritionistUserId,
      });
      await setDoc(doc(this.firestore, `newMessages/${this.userId}`), {
        ...message,
        patientUserId: this.userId,
        sentDate: new Date(),
        patientName: this.name,
        nutritionistUserId: this.nutritionistUserId,
        mercadopagoStatus: this.mercadopagoStatus,
        locations: this.locations
      });
    }
  }

  async clickSend() {
    const cleanText=()=>{
      this.text = "";
    }
    await this.sendMessage({
      text:this.text
    },cleanText)
  }
}
