
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FoodCard extends Vue {
  @Prop({ type: String, required: true }) typeOfFood!: string;
  @Prop({ type: Number, required: false, default: null }) rating!:
    | number
    | null;
  @Prop({ type: String, required: true }) image!: string;
  @Prop({ type: String, required: true }) foodDescription!: string;
  @Prop({ type: String, required: false, default: null }) doctorsNote!:
    | string
    | null;
  @Prop({ type: Boolean, required: true }) isRated!: boolean;
  @Prop({ type: String, required: true }) date!: string;
  @Prop({ type: Number, required: true }) foodId!: number;
  @Prop({ type: String, required: true }) state!: string;

  formatDate(date: string): string {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  }
}
