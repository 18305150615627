
import { Component, Vue } from "vue-property-decorator";
import Login from "./components/Login.vue";

@Component({
  components: {
    Login
  },
  data() {
    return {
      leftDrawerOpen: false
    };
  }
})
export default class App extends Vue {}
