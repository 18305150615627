import { render, staticRenderFns } from "./EditFood.vue?vue&type=template&id=8761d4d4"
import script from "./EditFood.vue?vue&type=script&lang=ts"
export * from "./EditFood.vue?vue&type=script&lang=ts"
import style0 from "./EditFood.vue?vue&type=style&index=0&id=8761d4d4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QForm,QSelect,QIcon,QInput,QFile,QImg,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QSelect,QIcon,QInput,QFile,QImg,QBtn})
