
import { Vue, Component, Model, Watch } from "vue-property-decorator";
import Proxy from "../../Proxy";

@Component
export default class CreateNewFood extends Vue {
  public foodType: string | null = null;
  public foodDescription: string | null = null;
  public image: File | null = null;
  public imagePreview: string | null = null;
  public date = this.getTodayDate();
  public isLoading = false;

  @Watch("image")
  onImageChange() {
    if (this.image) {
      this.imagePreview = URL.createObjectURL(this.image);
    } else {
      this.imagePreview = null;
    }
  }

  public onRejected() {
    this.$q.notify({
      color: "negative",
      position: "top",
      message:
        "El archivo seleccionado no es una imagen válida o excede el tamaño permitido (10 MB)."
    });
  }

  private getTodayDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  public onFileChange(e: Event): void {
    const inputElement = e.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.image = file;
      };
      reader.readAsDataURL(file);
    }
  }

  public async onSubmit() {
    if (!this.foodDescription || !this.date || !this.foodType) {
      this.$q.notify({
        color: "negative",
        position: "top",
        message: "Por favor, rellene todos los campos requeridos"
      });
      return;
    }

    // Confirmation dialog:
    this.$q
      .dialog({
        title: "Guardar alimento",
        message: "¿Está seguro que desea guardar este alimento?",
        cancel: true,
        persistent: true
      })
      .onOk(() => {
        //on Ok we make the request:
        this.isLoading = true;
        const formData = new FormData();
        formData.append("food_description", this.foodDescription!);
        formData.append("date", this.date);
        formData.append("type_of_food", this.foodType!);
        if (this.image) {
          formData.append("image", this.image);
        }
        formData.append("state", "Sin enviar");
        const config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };

        Proxy.post("/api/1.0/food", formData, config)
          .then(() => {
            // Everything is ok, we return to the last page:
            this.isLoading = false;
            this.$router.go(-1);
          })
          .catch(() => {
            // Something went wrong, we notify the user:
            this.isLoading = false;
            this.$q.notify({
              color: "negative",
              position: "top",
              message: "Error al guardar el alimento"
            });
          });
      });
  }
}
