import Vue from "vue";
import VueRouter, { Route, RawLocation, RouteConfig } from "vue-router";
import App from "./App.vue";
import "./registerServiceWorker";
import "./styles/quasar.scss";
//import  lang from "quasar/lang/es.js";
import "@quasar/extras/material-icons/material-icons.css";
import { Quasar, Notify, Dialog } from "quasar";
import Login from "./components/Login.vue";
import Plan from "./components/Plan.vue";
import Measurements from "./components/Measurements.vue";
import RecipesGallery from "./components/RecipesGallery.vue";
import Recipe from "./components/Recipe.vue";
import Videos from "./components/Videos.vue";
import ShoppingList from "./components/ShoppingList.vue";
import ProductCategory from "./components/ProductCategory.vue";

import PlanItem from "./components/PlanItem.vue";
import Home from "./components/Home.vue";
import Chat from "./components/Chat.vue";
import WeeklyMenu from "./components/WeeklyMenu.vue";
import ForgotPassword from "./components/ForgotPassword.vue";
import ResetPassword from "./components/ResetPassword.vue";
import AlimentaryRecord from "./components/alimentary_record/AlimentaryRecord.vue";
import CreateNewFood from "./components/alimentary_record/CreateNewFood.vue";
import EditFood from "./components/alimentary_record/EditFood.vue";

import UserdataEdition from "./components/UserdataEdition.vue";
import Main from "./components/Main.vue";
import Proxy from "./Proxy";
import { logEvent } from "./Firestore";

Vue.use(VueRouter);

Vue.use(Quasar, {
  config: {},
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: {
    Notify,
    Dialog
  }
  //lang: lang
});

Vue.config.productionTip = false;

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "main",
    component: Main,
    children: [
      {
        path: "plan",
        component: Plan
      },
      {
        path: "plan/item/:id",
        component: PlanItem,
        props: (route: any) => ({ id: route.params.id }),
        meta:{showTabs:false}
      },
      {
        path: "measurements",
        component: Measurements
      },
      {
        path: "recipes",
        component: RecipesGallery
      },
      {
        path: "recipes/:id",
        component: Recipe,
        props: (route: any) => ({ id: route.params.id }),
        meta:{showTabs:false}
      },
      {
        path: "videos",
        component: Videos
      },
      {
        path: "chat",
        component: Chat
      },
      {
        path: "menu",
        component: WeeklyMenu
      },
      {
        path: "",
        component: Home
      },{
        path:"/compras",
        component:ShoppingList,
        meta:{showTabs:false}
      },{
        path:"/compras/:id",
        component:ProductCategory,
        props: (route: any) => ({ id: route.params.id }),
        meta:{showTabs:false}
      }
    ]
  },
  { path: "/login", name: "login", component: Login },
  { path: "/mydata", component: UserdataEdition },
  { path: "/forgot", name: "forgot", component: ForgotPassword },
  {
    path: "/reset",
    name: "reset",
    component: ResetPassword,
    props: route => ({
      token: route.query.token,
      email: route.query.email
    })
  },
  {
    path: "/registro_alimentario", 
    component: AlimentaryRecord,
  },
  {
    path: "/registro_alimentario/nuevo_alimento",
    component: CreateNewFood,
  },{
    path: "/registro_alimentario/editar_comida/:id",
    component: EditFood,
    }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  // We get our csrf token first:
  await Proxy.get("/admin/login/", {
    data: null,
    headers: { "Content-Type": "application/json" }
  });

  const loggedUser = await Proxy.get("/api/1.0/yo");
  if (loggedUser.data.userId !== undefined) {
    if (to.name == "login") next(false);
    // Esta loggeado y se quiere loggear, volvemos atras
    else next();
    // Esta loggeado y no se quiere loggear, siga normal
  } else {
    if (["login", "forgot", "reset"].includes(to.name || "")) next();
    // Si no esta loggeado y se quiere loggear, siga
    else next({ name: "login" });
    // SI no esta loggeado y no se quiere loggear, loggeese
  }

  /*else {
    if (isPublicView(to.name || "")) next();
    // Si no esta loggeado y se quiere loggear, siga
    else next({ name: "login" });
    // SI no esta loggeado y no se quiere loggear, loggeese
  }*/
});


Vue.config.errorHandler = (err, vm, info) => {
  logEvent("vue_errorhandler_error",{
    message:err.message,
    name:err.name,
    stack:err.stack,
  })
};

new Vue({
  router: router,
  render: h => h(App)
}).$mount("#app");