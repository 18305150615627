
import { Component, Vue } from "vue-property-decorator";
import Proxy from "../../Proxy";
import FormattedText from "../FormattedText.vue";
import { openURL } from "quasar";
import FoodCard from "./FoodCard.vue";

const PAGE_AMOUNT = 6;

export interface FoodCardProps {
  id: number;
  image: string;
  type_of_food: string;
  food_description: string;
  date: string;
  is_rated: boolean;
  rating: number;
  revision_comment: string;
  state: string;
}

interface FoodResponse {
  foods: FoodCardProps[];
  has_next: boolean;
  has_prev: boolean;
  total: number;
}

@Component({
  components: {
    FormattedText,
    FoodCard
  }
})
export default class AlimentaryRecord extends Vue {
  foodCards: FoodCardProps[] = [];
  total = 0;
  rating = 0;
  foodTitle: string | null = null;
  page = 1;
  hasNext = false;
  hasPrev = false;
  loadMoreSpinner = false;
  sendToRevisionButton = false;

  mounted() {
    // Get the first food cards:
    this.fetchData(1, PAGE_AMOUNT);
  }

  handleImage(imageFile: string) {
    const imageSplit = imageFile.split('/')
    const lenght = imageSplit.length
    if (imageSplit[lenght - 1] === "None") {
      return require("../../assets/placeholder_food.png");
    }
    return `${imageFile}`;
  }

  open(link: string) {
    openURL(link);
  }

  fetchData(page: number = this.page, perPage: number = PAGE_AMOUNT) {
    this.loadMoreSpinner = true;
    Proxy.get<FoodResponse>("/api/1.0/food", {
      params: {
        page: page,
        'per_page': perPage
      }
    })
      .then(res => {
        this.foodCards = [...this.foodCards, ...res.data.foods]; //<- append the cards
        this.total = res.data.total;
        this.hasNext = res.data.has_next;
        this.hasPrev = res.data.has_prev;
        this.page = page;
        this.page++;
        this.sendToRevisionButton = this.isSendToRevisionButtonVisible(this.foodCards);
      })
      .catch(error => {
        if (error.response.status === 403) {
          this.$router.push("/");
        }
        this.$q.notify({
          message:
            "Error al cargar el registro alimentario, intentelo mas tarde",
          color: "red",
          position: "top"
        });
      });
    this.loadMoreSpinner = false;
  }

  isSendToRevisionButtonVisible(foodCards: FoodCardProps[]) {
    return foodCards.some(food => food.state === 'Sin Enviar');
  }

  sendToRevision() {
    //confirmation dialog:
    this.$q
      .dialog({
        title: "Enviar a revisión",
        message:
          "Una vez que envies a revsión, no se puede volver a editar. Tu nutricionista será notificado. ¿Está seguro que desea enviar el registro alimentario a revisión?",
        ok: "Sí",
        cancel: "No"
      })
      .onOk(() => {
        this.sendToRevisionRequest();
      });
  }

  sendToRevisionRequest() {
    Proxy.put("/api/1.0/food/send_to_revision")
      .then(() => {
        this.$q.notify({
          message: "Registro alimentario enviado a revisión",
          color: "green",
          position: "top"
        });
        this.foodCards = [];
        const pages = this.page;
        for (let i = 1; i < pages; i++) {
          this.fetchData(i, PAGE_AMOUNT);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          this.$q.notify({
            message: "No se tienen alimentos Sin Enviar para mandar a revisión.",
            color: "red",
            position: "top"
          })
          return;
        }
        if (error.response.status === 403) {
          this.$router.push("/");
        }
        this.$q.notify({
          message: "Error al enviar el registro alimentario a revisión",
          color: "red",
          position: "top"
        });
      });
  }
}
