
import { Component, Vue, Model } from "vue-property-decorator";
import Proxy from "../Proxy";
import { sortedPlanItems } from "./Logic";
interface ItemData {
  endTime: string;
}
interface PlanData {
  items: ItemData[];
}
@Component
export default class Plan extends Vue {
  items: ItemData[] = [];
  mounted() {
    Proxy.get<PlanData>("/api/1.0/plan").then((res) => {
      this.items = sortedPlanItems(res.data.items);
    });
  }
}
