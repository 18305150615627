
import { Component, Vue, Watch } from "vue-property-decorator";
import Proxy from "../Proxy";
import { openURL } from "quasar";
import NdaArticle from "./NdaArticle.vue";
import MeasurementChart from "./MeasurementChart.vue";
import { getIsTenant } from "@/isTenant";

type MetricKey = "masa_muscular" | "masa_adiposa" | "imo" | "suma_6_pliegues" | "cintura" | "peso"

interface Point {
  x: number
  y: number
  isLongTerm?: boolean
}

interface MetricDetails {
    key: MetricKey
    unit: string
    label: string
    color: string
    explanation: string
}

interface MeasurementVisit {
  measurement:{date:string}
  metric:Record<MetricKey, string|number|null>
}

interface AnthropometryObjective {
  value: number
  variable: string
  visit_number: number
  is_long_term: boolean
}

interface MeasurementsResponse {
  birthday:string
  email:string
  firstName:string
  lastName:string
  anthropometry_objectives: AnthropometryObjective[]
  measurements:MeasurementVisit[]
}

@Component({
  components: { NdaArticle, MeasurementChart }
})
export default class Measurements extends Vue {
  private isTenant: boolean = getIsTenant();
  downloadingAnthropometry=false
  private get chartCallbacks(){
    const measurementsData = this.measurementsData
    const selectedMetric = this.selectedMetric
    return {
      label: function(tooltipItems:any, data:any) {
        let texto = "Historial"
        if(tooltipItems.datasetIndex === 3) texto = "Objetivo"
        if(tooltipItems.datasetIndex === 1) return "Objetivo a largo plazo";
        if(tooltipItems.datasetIndex === 0) return "Objetivo alcanzado";
        return texto + " " + tooltipItems.value + " " + selectedMetric.unit;
      },
      title: function(tooltipItems:any, data:any) { 
        if(measurementsData===null){
          return "-"
        }
        const label:string = tooltipItems[0].label
        const measurementDate = measurementsData?.measurements[parseInt(label)-1].measurement.date
        const measurement = new Intl.DateTimeFormat('es').format(Date.parse(measurementDate));
        return `Visita ${label} (${measurement})`
      }

    }
  }
  allMetrics:MetricDetails[] = [
    {
      key: "masa_muscular",
      unit: "kg",
      label: "Masa muscular",
      color: "#116622",
      explanation: "Se obtiene a partir de la medición de los perímetros musculares. Nos permite ver su evolución con el cambio del entrenamiento y la alimentación."
    }, {
      key: "masa_adiposa",
      unit: "kg",
      label: "Masa grasa",
      color: "#221166",
      explanation: "Se obtiene a partir de la medición de los pliegues cutáneos. Nos permite ver su evolución con el cambio del entrenamiento y la alimentación."
    }, {
      key: "imo",
      unit: "",
      label: "IM:O",
      color: "#884444",
      explanation: "Se obtiene de dividir kg de masa muscular por kg de masa ósea. Indicador de cuánta masa muscular hay para cada kg de esqueleto. Los valores límites son cercanos a 5/1."
    }, {
      key: "suma_6_pliegues",
      unit: "cm",
      label: "Suma 6 pliegues",
      color: "#FF00FF",
      explanation: "Medida que hace referencia al tejido graso subcutáneo."
    }, {
      key: "cintura",
      unit: "cm",
      label: "Cintura",
      color: "#00FF00",
      explanation: "Medida utilizada para evaluar el riesgo cardiovascular y metabólico."
    }, {
      key: "peso",
      unit: "kg",
      label: "Peso",
      color: "#0000FF",
      explanation: "Indicador de la masa corporal total."
    }
  ]
  selectedMetric:MetricDetails = {
    key: "peso",
    unit: "",
    label: "",
    color: "",
    explanation: ""
  }
  private measurementsData:MeasurementsResponse|null=null
  get selectedMetricValues():(Point|null)[]{
    if(this.measurementsData===null){
      return []
    }else{
      return this.measurementsData.measurements.map((v, i) => {
        const val=v.metric[this.selectedMetric.key]
        if(val==null){
          return null
        }else{
          return {
            y: parseFloat(val.toString()),
            x: i + 1
          }
        }
      })
    }
  }
  get selectedMetricObjectives():(Point[]|null){   
    if(this.measurementsData===null){
      return []
    }else{
      return this.measurementsData.anthropometry_objectives
        .filter(v => v.variable == this.selectedMetric.key)
        .sort((a,b)=>a.visit_number-b.visit_number)
        .map(v => {
          return {
            y: v.value,
            x: v.visit_number,
            isLongTerm: v.is_long_term
          }
        })
    }
  }
  get selectedMetricMedals(): (Point|null)[]{
    if(this.measurementsData===null){
      return []
    }else{
      return this.measurementsData.anthropometry_objectives
        .filter(v => v.variable == this.selectedMetric.key && !v.is_long_term)
        .map(v => {
          let point: Point | null = null;
          if(!this.measurementsData?.measurements.length || v.visit_number > this.measurementsData?.measurements.length) return null;
          if(['masa_adiposa', 'peso', 'suma_6_pliegues', 'cintura'].includes(this.selectedMetric.key)){
            if(v.value >= (this as unknown as any).measurementsData?.measurements[v.visit_number - 1].metric[this.selectedMetric.key]){
              point = {
                y: v.value,
                x: v.visit_number,
                isLongTerm: v.is_long_term
              }
            }
          } else {
            if(v.value <= (this as unknown as any).measurementsData?.measurements[v.visit_number - 1].metric[this.selectedMetric.key]){
              point = {
                y: v.value,
                x: v.visit_number,
                isLongTerm: v.is_long_term
              }
            }
          }
          return point
        })
    }
  }

  visibleMetrics:string[] = []
  private get showSingleMeasurement():boolean {
    return (
      (this.selectedMetricValues.filter( v => v !== null ).length === 1) 
      && 
      ((this.selectedMetricObjectives??[]).filter( v => v !== null ).length === 0)
    )
  }
  private get singleMeasurementValue():Record<string, any> | null {
    if(this.showSingleMeasurement){
      const singleMeasurement = this.measurementsData!.measurements[this.selectedMetricValues.find(v=>v!==null)!.x - 1]

      const objList = this.measurementsData!.anthropometry_objectives
        .filter(v => v.variable == this.selectedMetric.key)
        .filter(v => v.visit_number >= this.selectedMetricValues.find(v=>v!==null)!.x)
        .sort((a, b) => { 
          if(a.visit_number < b.visit_number) return -1
          if(a.visit_number > b.visit_number) return 1
          return 0
        })
      return { 
        ...singleMeasurement,
        nextObjective: objList[0]
      }
      
    }else{
      return null
    }
  }

  async downloadAnthro(){
    this.downloadingAnthropometry=true
    try{
      const res = await Proxy.get("/api/1.0/anthropometry/yo/measurements.pdf", {responseType:"blob"})
      this.downloadingAnthropometry=false


      
      const fileURL = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement('a')
      link.href = fileURL
      link.download = res.headers['content-disposition'].match(/(?=filename=).*/g)[0].replace("filename=","")
      document.body.appendChild(link);
      link.click();
    }catch(e){
      this.downloadingAnthropometry=false
    }
  }

  mounted() {
    Proxy.get("/api/1.0/anthropometry/yo/").then((res) => {
      this.measurementsData = res.data
      res.data.measurements.map((obj: any) => {
        return obj.metric
      }).forEach((metric: any) => {
        this.allMetrics.forEach((canonMetric: MetricDetails) => {
          if(!this.visibleMetrics.includes(canonMetric.key) && metric[canonMetric.key] != null)
            this.visibleMetrics.push(canonMetric.key)
        })
      });
      if(this.visibleMetrics.length > 0) this.selectedMetric = this.allMetrics.filter(m => this.visibleMetrics.includes(m.key))[0]
    });
    
  }



}
