
import { Component, Vue, Watch } from "vue-property-decorator";
import Proxy from "../Proxy";
import { openURL } from "quasar";
import { sortedPlanItems } from "./Logic";

interface Recipe {
  name: string;
  image: string;
  link: string;
  id: number;
}

interface PlanItem {
  name: string;
  endTime: string;
  id: number;
}

interface RecipesResponse {
  recipes: Recipe[];
  availablePlanItems: PlanItem[];
  currentPlanItem: { id: number };
  favoriteRecipes:Array<number>
}

interface RecipeRow {
  recipes: Recipe[]
  id:number
}

@Component
export default class RecipesGallery extends Vue {
  recipes: readonly Recipe[] = [];
  availablePlanItems: PlanItem[] = [];
  selectedPlanItemId: number | null = null;
  currentPlanItemId:number|null=null;
  favoriteRecipes:Array<number>=[];

  get unselectedPlanItems() {
    return this.availablePlanItems.filter(
      ({ id }) => id != this.selectedPlanItemId
    );
  }
  isFavorite(recipe:Recipe){
    return this.favoriteRecipes.includes(recipe.id)
  }
  async toggleFavorite(recipe:Recipe){
    if(this.isFavorite(recipe)){
      this.favoriteRecipes = this.favoriteRecipes.filter(recipeId=>recipeId != recipe.id)
      await Proxy.delete(`/api/1.0/favoriteRecipe?recipe_id=${recipe.id}`);
    }else{
      this.favoriteRecipes.push(recipe.id)
      await Proxy.post(`/api/1.0/favoriteRecipe?recipe_id=${recipe.id}`);
    }
  }

  get scrollRows():readonly RecipeRow[]{
    const ret:RecipeRow[]=[]
    let acum:Recipe[]=[]
    this.recipes.forEach((recipe)=>{
      if(acum.length<4){
        acum.push(recipe)
      }else{
        ret.push({recipes:acum,id:recipe.id})
        acum=[]
      }
    })
    if(acum.length>0){
      ret.push({recipes:acum,id:acum[0].id})
    }

    return Object.freeze(ret)
  }
  get selectedPlanItem() {
    return (
      this.availablePlanItems.filter(
        ({ id }) => id == this.selectedPlanItemId
      )[0] || null
    );
  }
  get scrollContentRef(): Vue | Element | undefined {
    if (Array.isArray(this.$refs["scrollContent"])) {
      return this.$refs["scrollContent"][0] as Vue | Element;
    } else {
      return this.$refs["scrollContent"] as Vue | Element;
    }
  }
  get heightOfHeader():number{
    if(this.scrollContentRef instanceof Element){
      return this.scrollContentRef.getBoundingClientRect().y
    }else{
      return 150;//this.scrollContent.$el.getBoundingClientRect().y
    }
  }
  @Watch("selectedPlanItemId")
  async changeSelectedItem(newVal: number | null) {
    this.recipes = [];
    this.updateRecipes(newVal)
  }
  async updateRecipes(selectedItem: number | null): Promise<void> {
    const now = new Date();
    const params: Record<string, any> = {
      hour: now.getHours(),
      minute: now.getMinutes(),
      paged:false,
      ...((selectedItem !== null)?{
        planItem:selectedItem
      }:{})
    };
    const res = await Proxy.get<RecipesResponse>("/api/1.0/recipes", {
      params,
    });

    console.log("selectedPlanItemId",this.selectedPlanItemId)
    console.log("selectedItem",selectedItem)

    if(this.selectedPlanItemId==selectedItem){
      this.recipes = Object.freeze(res.data.recipes);
      this.availablePlanItems = sortedPlanItems(res.data.availablePlanItems);
      this.currentPlanItemId=res.data.currentPlanItem.id
    }
    this.favoriteRecipes=res.data.favoriteRecipes
  }
  mounted() {
    this.updateRecipes(null).then(() => {
      this.selectedPlanItemId = this.currentPlanItemId;
    });
  }
  open(link: string) {
    openURL(link);
  }
}
