import { render, staticRenderFns } from "./NdaArticle.vue?vue&type=template&id=5017167b"
import script from "./NdaArticle.vue?vue&type=script&lang=ts"
export * from "./NdaArticle.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QItem,QItemSection,QAvatar,QImg,QItemLabel,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QItem,QItemSection,QAvatar,QImg,QItemLabel,QIcon})
