import axios from "axios"

const _axios = axios.create();
let token = "";
_axios.interceptors.response.use(
  function(response) {
    if(response.data && response.data.response && response.data.response.csrf_token){
      token = response.data.response.csrf_token;
      //console.log("token is: " + token)
    }
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

_axios.interceptors.request.use(function (config) {
  if (["post", "delete", "patch", "put"].includes(config.method || "")) {
      if (token !== '') {
         config.headers["X-CSRF-Token"] = token
      }
  }
  return config;
}, function(error) {
  // Do something with request error
    return Promise.reject(error);
});

export default _axios