
import { Component, Vue, Model } from "vue-property-decorator";
import Proxy from "../Proxy";
import FormattedText from "./FormattedText.vue";

interface ItemData {
  endTime: string;
}
interface PlanData {
  items: ItemData[];
  description:string|null
}

@Component({
  components: {
    FormattedText,
  },
})
export default class ShoppingList extends Vue {
  items: ItemData[] = [];
  description:string|null=null
  mounted() {
    Proxy.get<PlanData>("/api/1.0/productCategories").then((res) => {
      this.items = res.data.items
      this.description=res.data.description
    });
  }
}
