
import { Scatter } from "vue-chartjs";
import { colors } from 'quasar';
const medalPoint = new Image()
medalPoint.src = require('@/assets/medal.svg')
medalPoint.height = 48
medalPoint.width = 48

const longTermGoalIcon = new Image()
longTermGoalIcon.src = require('@/assets/long_term_goal.svg')
longTermGoalIcon.width=128
longTermGoalIcon.height=128

require('@/assets/long_term_goal.svg')
function makeGoalCharPoint(value:number,unit:string){
  const canvas = (document.createElement("CANVAS") as HTMLCanvasElement);
  canvas.width=128
  canvas.height=128
  const ctx = canvas.getContext("2d")
  if(ctx==null){
    return longTermGoalIcon
  }
  ctx.drawImage(longTermGoalIcon, 0, 0);
  ctx.textAlign = "center"; 
  ctx.font = "26px Arial";
  ctx.fillStyle="#FFFFFF"
  ctx.fillText(`${value.toString()} ${unit}`,64,48, 128)


  const customGoalPoint = new Image()
  customGoalPoint.src = canvas.toDataURL()
  customGoalPoint.height = 64
  customGoalPoint.width = 64
  return customGoalPoint
}


export default {
  extends: Scatter,
  props: ["values", "callbacks", "objectives", "longTermObjectives", "medals", "unit"],
  mounted() {
    const primaryColor = colors.getBrand('primary');
    (this as unknown as any).renderChart(
      {
        labels: (this as unknown as any).values
          .filter((v:any) => v != null)
          .map((v:any, i:any) => (i + 1).toString()),
        datasets: [
          {
            label: "Objetivo cumplido",
            borderColor: "#00FF00",
            data: (this as unknown as any).medals,
            pointHoverRadius: 10,
            pointStyle: medalPoint,
            pointRadius: 10,
            showLine: false
          },{
            label: "Largo plazo",
            borderColor: "#FF0000",
            data: (this as unknown as any).longTermObjectives,
            pointHoverRadius: 10,
            pointStyle: (this as unknown as any).longTermObjectives.map((o:{y:number})=>makeGoalCharPoint(o.y,(this as unknown as any).unit)),
            pointRadius: 10,
            showLine: false
          }, {
            label: "Historial",
            borderColor: primaryColor,
            fill: false,
            data: (this as unknown as any).values,
            pointHoverRadius: 10,
            pointHitRadius: 10,
            pointRadius: 2.5,
            pointBorderWidth: 5,
            showLine: true,
          }, {
            label: "Objetivo",
            borderColor: "#1074bc",
            fill: false,
            data: (this as unknown as any).objectives,
            pointHoverRadius: 10,
            pointHitRadius: 10,
            pointRadius: 2.5,
            pointBorderWidth: 5,
            showLine: true,
            borderDash: [7, 5]
          }
        ],
      },
      {
        responsive: true,
        tooltips: {
          enabled: true,
          callbacks: ( (this as unknown as any).callbacks === undefined ) ? {} : (this as unknown as any).callbacks,
        },
        legend: {
          display: false
        },
        layout: {
          padding: {
              left: 32,
              right: 32,
              top: 32,
              bottom: 32
          }
        }
      }
    );
  },
};
